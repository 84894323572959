<template>
    <!-- <div class="content-wrapper"> -->
        <div class="items">
            <div class="container mt-5">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        
                        <div class="card">
                            <div class="card-header">
                                ITEMS e hhhhh
                            </div>
                            <!-- {{ name }} --> 
                            <div class="card-body">
                                <!-- {{ path }} -->
                                <router-link :class="['btn btn-md btn-success mb-2']" :to="menu+'/create'">TAMBAH item</router-link> 
                                <hr> <!-- ddddd {{ menu }} yyyy -->
    <!-- {{ items }} -->
                                <div class="table-responsive mt-2">
                                    <table class="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th>KODE</th>
                                            <th>NAMA</th>
                                            <th>KET</th>
                                            <th>AKSI</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        
                                        <tr v-for="item in items" :key="item.id">
                                            <td>{{ item.item_code }}</td>
                                            <td>{{ item.item_name }}</td>
                                            <td>{{ item.item_desc }}</td>
                                            <td class="text-center">
                                                <!-- <router-link :to="{name: 'edit', params: { id: item.id }}" class="btn btn-sm btn-primary mr-2">EDIT</router-link> -->
                                                <router-link class="btn btn-sm btn-primary mr-2" :to="'/set_item/edit/' + item.id ">Edit</router-link>
                                                <button @click.prevent="itemDelete(item.id)" class="btn btn-sm btn-danger">HAPUS</button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div>     -->
</template>


<script>
    //import axios from 'axios'

    export default {

        data() {
            return {
                items: [],
                name : this.$route.name,
                menu : this.$route.meta.menu,
                path : this.$route.path,
            }
        },
         /* var headers= {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, item, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            } */
        created() {
            /* var headers = { 
                "Access-Control-Allow-Origin": "*" 
            }            
            axios.get('http://localhost:8000/api/items', {}, headers).then(response => {
                this.items = response.data.data;
               // console.log(this.items);
            }); */

             this.getServices();
            
        },
         methods: {
            async getServices(route = this.$route) {
                this.isLoading = true;
                console.log(route);
                console.log('item');
                const params = { 
                    page: 's',
                    limit: 10,
                };
                var response = await this.apiGets(`items`, { params });
                if(response.status == 200) {
                    //this.lists = { ...response.data.data };
                    this.items = { ...response.data.data };
                }

                this.isLoading = false;
            },
            itemDelete(id)
            {
               /*  axios.delete(`http://localhost:8000/api/items/${id}`)
                    .then(response => {
                        this.items.splice(this.items.indexOf(id), 1);
                        console.log(response);
                    }).catch(error => {
                    console.log(error.response);
                }); */
                this.$swal({
                    title: "Anda Yakin Akan Menghapus data?",            
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'Ya',
                    cancelButtonColor: '#3085d6',
                    cancelButtonText: "Batal"
                
                }).then( async  result => {
                    if (result.isConfirmed) {
                        this.isLoading = true;
                        var response = await this.apiDeletes(`items/${id}`);
                        if(response.status == 200) {
                            this.items = { ...response.data.data };
                        }
                        this.getServices();

                        this.isLoading = false;
                        console.log(result);
                    }                        
                    console.log(id);
          
                 
                });
            }
        }
    }
</script>